import {
  Overlay,
  ActionIcon,
  Container,
  createStyles,
  Title,
  Flex,
  Box,
  Text,
  Button,
  Group,
} from "@mantine/core"
import React from "react"
import { IconPlayerPlayFilled } from "@tabler/icons-react"
import { Image } from "../../shared/ui"
import { useMediaQuery } from "@mantine/hooks"
import { wrapUrlWithCurrentQuery } from "shared/utils/url"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("md")]: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xl} * 1.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },

    backgroundColor: theme.colors.blue[0],
  },

  title: {
    fontSize: 36,

    [theme.fn.smallerThan("md")]: {
      fontSize: 24,
    },
  },

  box: {
    maxWidth: "50%",
    overflow: "hidden",
    position: "relative",
    borderRadius: 4,
    boxShadow: theme.shadows.xl,

    [theme.fn.smallerThan(768)]: {
      maxWidth: 640,
    },
  },

  info: {
    gap: 24,
    justifyContent: "center",

    [theme.fn.smallerThan(768)]: {
      alignItems: "center",
    },
  },

  flex: {
    gap: 48,

    [theme.fn.smallerThan("md")]: {
      gap: 24,
    },

    [theme.fn.smallerThan(768)]: {
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },

  subTitle: {
    fontSize: 18,

    [theme.fn.smallerThan(768)]: {
      textAlign: "center",
      fontSize: 16,
    },
  },
}))

export function TestDrive(): React.JSX.Element {
  const { classes } = useStyles()
  const md = useMediaQuery(`(max-width: 768px)`)
  const is425 = useMediaQuery(`(max-width: 425px)`)

  return (
    <div className={classes.wrapper}>
      <Container size={1320} sx={{ zIndex: 1 }}>
        <Flex className={classes.flex}>
          <Box className={classes.box}>
            <Image
              objectFit="contain"
              src="assets/test-drive.jpg"
            />
            <Overlay opacity={0.1} blur={0} center>
              <ActionIcon
                gradient={{ from: "blue", to: "cyan", deg: 105 }}
                size={56}
                radius={4}
                variant="gradient"
                component="a"
                href={wrapUrlWithCurrentQuery("https://academy.bodycoach.pro/buy/228")}
                target="_blank"
              >
                <IconPlayerPlayFilled size={20}/>
              </ActionIcon>
            </Overlay>
          </Box>

          <Flex className={classes.info} direction="column">
            <Title className={classes.title} order={2}>
              Устройте курсу&nbsp;{is425 && <br/>}тест-драйв
            </Title>

            <Text className={classes.subTitle}>
              Посмотрите 5 уроков бесплатно и убедитесь, что обучение вам точно подходит.
            </Text>

            <Group>
              <Button
                radius={4}
                size={md ? "md" : "lg"}
                variant="gradient"
                gradient={{ from: "blue", to: "cyan", deg: 105 }}
                component="a"
                href={wrapUrlWithCurrentQuery("https://academy.bodycoach.pro/buy/228")}
                target="_blank"
              >
                Получить доступ бесплатно!
              </Button>
            </Group>
          </Flex>
        </Flex>

      </Container>
    </div>

  )
}
